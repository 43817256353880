<template>
  <div>
    <h4>Seleccionar proyecto a configurar</h4>
    <multiselect
      v-model="selectedProject"
      :options="projects"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      placeholder="Buscar"
      :custom-label="setName"
      track-by="project_id"
      @select="setSelectedProjects"
    ></multiselect>
    <Displayed v-if="projectId && projectName" />
  </div>
</template>

<script>
import Displayed from "./components/Displayed";

export default {
  name: "App",
  props: {
    customer_id: Number,
    customer_name: String,
    user_id: Number,
    version: { type: Number, default: 1 },
  },
  data() {
    return {
      selectedProject: "",
    };
  },
  created() {
    this.$store.dispatch("basic/setStateUser", {
      superUser: this.admin,
      user_id: this.user_id,
    });
    this.$store.dispatch("basic/setVersion", this.version);
    this.$store.dispatch("basic/setCustomerId", this.customer_id);
    this.$store.dispatch("basic/setCustomerName", this.customer_name);
    this.$store.dispatch("basic/setProjects");
  },
  components: {
    Displayed,
  },
  methods: {
    setSelectedProjects(selectedProject) {
      this.$store.dispatch("basic/setProjectId", null);
      this.$store.dispatch("basic/setProjectName", null);
      let self = this;
      setTimeout(function () {
        self.$store.dispatch("basic/setProjectId", selectedProject.project_id);
        self.$store.dispatch("basic/setProjectName", selectedProject.name);
      }, 1);
    },
    setName(project) {
      return `${project.name} (${project.project_id})`;
    },
  },
  computed: {
    projects() {
      return this.$store.getters["basic/projects"];
    },
    projectName() {
      return this.$store.getters["basic/projectName"];
    },
    projectId() {
      return this.$store.getters["basic/projectId"];
    },
  },
};
</script>

<style lang="scss">
admin-meet {
  @import "~bootstrap/scss/bootstrap.scss";
  @import "~bootstrap-vue/src/index.scss";
  @import "assets/sass/multiselect.scss";
  * {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
  .fc-toolbar-chunk .fc-today-button,
  .fc-toolbar-chunk .fc-prev-button,
  .fc-toolbar-chunk .fc-next-button {
    display: none;
  }
  .content-setting {
    padding: 2em;
  }
  .fc-toolbar-chunk .fc-toolbar-title {
    text-transform: capitalize;
  }
  .month-picker__container.month-picker--default {
    z-index: 99;
  }
  .content-hours-by-day .custom-control.custom-checkbox {
    display: inline-block;
    padding: 0.5em 1.2em 0.5em 0.5em;
    margin: 0.5em;
  }
  .content-hours-by-day {
    text-align: center;
  }
}
</style>
