<template>
    <div class="content-setting">
        <b-container>
            <h3>Ajustes</h3>
            <b-alert show variant="secondary">
                <b-row>
                    <b-col cols="12">
                        <h4>Copiar mes</h4>
                        <label>*Solo se copia la estructura base</label>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Mes plantilla">
                            <month-picker-input  @change="setFromDate" lang="es" placeholder="Seleciona Mes" :no-default="true"></month-picker-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Mes que recibira la copia">
                            <month-picker-input  @change="setToDate" lang="es" placeholder="Seleciona Mes" :no-default="true"></month-picker-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12 text-right">
                        <b-button class="mt-4" size="lg" variant="outline-primary" :disabled="selectedFromMonth == 0 && selectedToMonth == 0" @click="sendfromTo()">Copiar</b-button>
                    </b-col>
                </b-row>
            </b-alert>
            <b-alert show variant="secondary">
                <b-row>
                    <b-col cols="12">
                        <h4>Replicar {{projectName}} a distintos proyectos</h4>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Mes a replicar">
                            <month-picker-input  @change="setProjectsDate" lang="es" placeholder="Seleciona Mes" :no-default="true"></month-picker-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Seleccionar proyectos">
                            <multiselect v-model="selectedProjects" :options="projects" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Buscar" :custom-label="setName" track-by="project_id"></multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12 text-right">
                        <b-button size="lg" variant="outline-primary" :disabled="selectedProjects.length == 0 && selectedProjectsMonth > 0" @click="sendProjects()">Replicar</b-button>
                    </b-col>
                </b-row>
            </b-alert>
        </b-container>
    </div>
</template>
<script>
import { MonthPickerInput } from 'vue-month-picker'
import qs from 'qs'
export default {
  name: 'Setting',
  props: {
  },
  components: {
    MonthPickerInput
  },
  updated() {
  },
  watch: {
    'selectedMonth': function() {
      this.setHours()
    },
    'year': function() {
      this.setHours()
    }
  },
  data() {
    return {
      rangeByHour: true,
      week: [],
      year: '',
      selectedProjectsMonth: 0,
      selectedProjectsYear: null,
      rangeHour: 'halfAnHour',
      rangeHours: [
        { text: 'Media Hora', value: 'halfAnHour' },
        { text: 'Hora', value: 'hour' },
      ],
      selectedHours: [],
      selectedMonth: 0,
      selectedFromMonth: 0,
      selectedToMonth: 0,
      selectedFromYear: null,
      selectedToYear: null,
      selectedProjects: [],
    }
  },
  computed: {
    projects() {
        return this.$store.getters['basic/projects']
    },
    projectName() {
        return this.$store.getters['basic/projectName']
    },
    projectId() {
        return this.$store.getters['basic/projectId']
    }
  },
  methods: {
    setFromDate(date) {
      this.selectedFromMonth = date.monthIndex
      this.selectedFromYear = date.year
    },
    setToDate(date) {
      this.selectedToMonth = date.monthIndex
      this.selectedToYear = date.year
    },
    setProjectsDate(date) {
      this.selectedProjectsMonth = date.monthIndex
      this.selectedProjectsYear = date.year
    },
    async setHours() {
      let params = {}
      params.schedule = {
        customer_id: this.$store.getters['basic/customerId'],
        user_id: this.$store.getters['basic/userId'],
        month_id: this.selectedMonth,
        year: this.year
      }
      params.project = {
        name: this.projectName,
        project_id: this.projectId,
        customer_id: this.$store.getters['basic/customerId'],
      }
      let response = await this.axios.get(`admin/schedules/users?${qs.stringify(params)}`)
      if (response.data.id) {
        this.selectedHours = response.data.selected_hours
        this.rangeHour = response.data.range_type
      } else {
        this.selectedHours = []
      }
    },
    sendfromTo(){
        let params = {
            customer_id: this.$store.getters['basic/customerId'], 
            tool: 'fromTo',
            user_id: this.$store.getters['basic/userId'], 
            from: {
                month: this.selectedFromMonth, 
                year: this.selectedFromYear
            },
            to: {
                month: this.selectedToMonth, 
                year: this.selectedToYear
            },
            project: {
                customer_id: this.$store.getters['basic/customerId'], 
                name: this.projectName,
                project_id: this.projectId
            }
        }
        this.send(params)
    },
    sendProjects(){
        let params = {
            customer_id: this.$store.getters['basic/customerId'], 
            tool: 'projects',
            user_id: this.$store.getters['basic/userId'], 
            date: {
                month: this.selectedProjectsMonth, 
                year: this.selectedProjectsYear
            },
            project_from: {
                name: this.projectName,
                project_id: this.projectId
            },
            projects_to: this.selectedProjects
        }
        this.send(params)
    },
    send(params) {
      const self = this
      this.axios.post('admin/schedules/tools', params).then(() => {
        self.$swal("¡Actualizado!", "Cambios guardados con éxito", "success")
      })
    },
    setName(project){
        return `${project.name} (${project.project_id})`
    },
    getOptions(hours) {
      if (hours.length === 0) return []
      return hours.map(hour => {
        return {
          text: hour.name,
          value: hour.id
        }
      })
    }
  }
}
</script>
