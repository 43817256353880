<template>
  <div>
    <b-modal
      size="xl"
      :title="title"
      :id="id"
      :scrollable="true"
      :static="true"
      :lazy="true"
      >
        <slot name="body">
        </slot>
        <template #modal-footer>
          <slot name="footer">
          </slot>
        </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    id: String,
    title: String
  },
  components: {
  },
  created() {
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
