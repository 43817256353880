export default {
  namespaced: true,
  state: {
    exist: false
  },
  mutations: {
    setExistUser: (state, value) => state.exist = value
  },
  actions: {
    setExistUser: (context, value) => {
      context.commit('setExistUser', value)
    }
  },
  getters: {
    existUser: (state) => state.exist
  }
}
