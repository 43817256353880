import Vue from 'vue';
import Vuex from 'vuex';
import basic from './basic';
import schedule from './schedule';
import user from './user';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    basic: basic,
    schedule: schedule,
    user: user
  }
})
