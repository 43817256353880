<template>
  <div class="content-setting">
    <h3>Ajustes</h3>
    <br>
    <div>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Selecciona Mes">
            <month-picker-input  @change="setDate" lang="es" placeholder="Seleciona Mes" :no-default="true"></month-picker-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Rango Horario Agendamiento" v-if="selectedMonth !== 0">
            <b-form-radio-group
               v-model="rangeHour"
               :options="rangeHours"
               size="lg"
               switches
             ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
        </b-col>
      </b-row>
      <br>
      <table class="table table-striped" v-if="selectedMonth !== 0">
        <thead>
          <tr>
            <th scope="col" v-for="(day, indexDay) in week" :key="`day-${indexDay}`">
              {{day.name}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-for="(day, indexDay) in week" :key="`day-${indexDay}`">
              <b-form-group>
                <template #label>
                  <b-form-checkbox
                    size="md"
                    v-model="day.allSelected"
                    aria-describedby="flavours"
                    aria-controls="flavours"
                    @change="toggleAll(day)"
                  >
                  Seleccionar Todo:
                  </b-form-checkbox>
                </template>
                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-model="selectedHours"
                    :aria-describedby="ariaDescribedby"
                    :options="getOptions(day.work_hours)"
                    size="lg"
                    name="flavour-2a"
                    stacked
                  ></b-form-checkbox-group>
                </template>
              </b-form-group>
            </td>
          </tr>
        </tbody>
      </table>
      <b-button size="lg" variant="outline-primary" @click="send" v-if="selectedMonth !== 0">Guardar</b-button>
    </div>
  </div>
</template>
<script>
import { MonthPickerInput } from 'vue-month-picker'
import qs from 'qs'
export default {
  name: 'Setting',
  props: {
  },
  components: {
    MonthPickerInput
  },
  created() {
    this.getDays()
  },
  updated() {
  },
  watch: {
    'selectedMonth': function() {
      this.setHours()
    },
    'year': function() {
      this.setHours()
    }
  },
  data() {
    return {
      rangeByHour: true,
      week: [],
      year: '',
      rangeHour: 'halfAnHour',
      rangeHours: [
        { text: 'Media Hora', value: 'halfAnHour' },
        { text: 'Hora', value: 'hour' },
      ],
      selectedHours: [],
      selectedMonth: 0,
    }
  },
  methods: {
    toggleAll(day) {
      let options = this.getOptions(day.work_hours)
      let ids = options.map(option => option.value)
      if (day.allSelected) {
        this.selectedHours = Array.from(new Set(this.selectedHours.concat(ids)))
      } else {
        this.selectedHours = this.selectedHours.filter((hour) => !ids.includes(hour))
      }
    },
    setDate(date) {
      this.selectedMonth = date.monthIndex
      this.year = date.year
    },
    async setHours() {
      let params = {}
      params.schedule = {
        customer_id: this.$store.getters['basic/customerId'],
        user_id: this.$store.getters['basic/userId'],
        month_id: this.selectedMonth,
        year: this.year
      }
      params.project = {
        name: this.$store.getters["basic/projectName"],
        project_id: this.$store.getters["basic/projectId"],
        customer_id: this.$store.getters['basic/customerId'],
      }
      let response = await this.axios.get(`admin/schedules/users?${qs.stringify(params)}`)
      if (response.data.id) {
        this.selectedHours = response.data.selected_hours
        this.rangeHour = response.data.range_type
      } else {
        this.selectedHours = []
      }
    },
    send() {
      const self = this
      let params = {}
      params.schedule = {
        month_id: this.selectedMonth,
        range_type: this.rangeHour,
        schedule_type: `${this.$store.getters['basic/superUser'] ? 'global' : 'staff'}`,
        user_id: this.$store.getters['basic/userId'],
        customer_id: this.$store.getters['basic/customerId'],
        selected_hours: this.selectedHours,
        year: this.year
      }
      params.project = {
        name: this.$store.getters["basic/projectName"],
        project_id: this.$store.getters["basic/projectId"],
        customer_id: this.$store.getters['basic/customerId'],
      }
      this.axios.post('admin/schedules', params).then(() => {
        self.$swal("¡Actualizado!", "Cambios guardados con éxito", "success")
      })
    },
    getOptions(hours) {
      if (hours.length === 0) return []
      return hours.map(hour => {
        return {
          text: hour.name,
          value: hour.id
        }
      })
    },
    async getDays() {
      const response = await this.axios.get('admin/days')
      this.week = response.data.map(obj=> ({ ...obj, allSelected: false }))
      this.$store.dispatch('schedule/setWeek', response.data);
    }
  }
}
</script>
