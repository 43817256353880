<template>
  <div class="content-setting">
    <h3>Usuario</h3>
    <div>
      <b-alert
        variant="danger"
        show
        v-if="!this.$store.getters['user/existUser']"
      >
        Actualize sus datos, para crear su calendario.
      </b-alert>
    </div>
    <b-form @submit="onSubmit">
      <b-row>
        <b-col cols="6">
          <b-form-group id="input-group-1" label="Nombre:" label-for="input-1">
            <b-form-input
              size="lg"
              id="input-1"
              v-model="form.name"
              type="text"
              placeholder="Nombre"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            id="input-group-1"
            label="Apellido:"
            label-for="input-2"
          >
            <b-form-input
              size="lg"
              id="input-2"
              v-model="form.lastname"
              type="text"
              placeholder="Apellido"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            id="input-group-1"
            label="Correo:"
            label-for="input-3"
            description="Correo electronico, para notificaciones moby-meet. Si se quiere poner más de un correo, separar por comas."
          >
            <b-form-input
              size="lg"
              id="input-3"
              v-model="form.email"
              type="text"
              placeholder="example@mobysuite.com"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            id="input-group-1"
            label="Link Videoconferencia:"
            label-for="input-3"
            description="Link específico del ejecutivo para viedoconferencias."
          >
            <b-form-input
              size="lg"
              id="input-3"
              v-model="form.meeting_url"
              type="text"
              placeholder="https://meet.google.com/aaa-bbb-ccc"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button size="lg" variant="outline-primary" type="submit"
        >Enviar</b-button
      >
    </b-form>
  </div>
</template>
<script>
import qs from "qs";
export default {
  name: "User",
  props: {},
  components: {},
  created() {
    this.getUser();
  },
  updated() {},
  data() {
    return {
      form: {
        id: null,
        email: "",
        name: "",
        lastname: "",
        meeting_url: "",
      },
    };
  },
  watch: {},
  methods: {
    async getUser() {
      const params = {};
      params.user = {
        customer_id: this.$store.getters["basic/customerId"],
        user_id: this.$store.getters["basic/userId"],
      };
      const response = await this.axios.get(
        `admin/users/${this.$store.getters["basic/userId"]}?${qs.stringify(
          params
        )}`
      );
      this.form = response.data;
      this.$store.dispatch("user/setExistUser", this.form.id ? true : false);
    },
    onSubmit(event) {
      event.preventDefault();
      const self = this;
      const params = {};
      params.user = {
        customer_id: this.$store.getters["basic/customerId"],
        user_id: this.$store.getters["basic/userId"],
        email: this.form.email,
        name: this.form.name,
        lastname: this.form.lastname,
        meeting_url: this.form.meeting_url,
      };
      this.axios
        .patch(
          `admin/users/${this.$store.getters["basic/userId"]}?${qs.stringify(
            params
          )}`
        )
        .then((response) => {
          self.$swal("¡Actualizado!", "Cambios guardados con éxito", "success");
          self.form = response.data;
          self.$store.dispatch(
            "user/setExistUser",
            this.form.id ? true : false
          );
        });
    },
  },
};
</script>
