<template>
  <div>
    <Modal :id="`detailDay-${pivotDate}`" :title="`Horario: Dia ${this.$moment(pivotDate).format('DD-MM-YYYY')}`">
      <template v-slot:body>
        <div class="content-hours-by-day">
          <b-form-checkbox-group
            v-model="selectedHours"
            :options="getHours(pivotDate)"
            size="lg"
            name="flavour-2a"
            stacked
          ></b-form-checkbox-group>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <b-button size="lg" variant="outline-primary" :disabled="selectedHours.length > 0 ? false : false" @click="sendScheduleByDay(pivotDate)">Guardar</b-button>
        </div>
      </template>
    </Modal>
    <div class="content-fullcalendar">
        <FullCalendar ref="fullCalendar" :options="calendarOptions" />
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import '@fullcalendar/core/vdom'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es';
import Modal from './Modal'
export default {
  props: {
    date: String
  },
  name: 'Calendar',
  data(){
    return {
      newArrayDates: [],
      pivotDate: '',
      pivotOptions: [],
      selectedHours: [],
      calendarOptions: {
        locale: esLocale,
        plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin ],
        initialDate: this.date,
        initialView: 'dayGridMonth',
        weekends: true,
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        events: []
      }
    }
  },
  watch: {
    date: function () {
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.gotoDate(this.date)
      this.setWorkDays(this.date)
      this.newArrayDates = []
    }
  },
  computed: {
  },
  components: {
    FullCalendar, Modal
  },
  created() {
    this.setWorkDays(this.date)
  },
  methods: {
    sendScheduleByDay(date) {
      let self = this
      let params = {}
      params.schedule_by_date = {
        user_id: this.$store.getters['basic/userId'],
        customer_id: this.$store.getters['basic/customerId'],
        month_id: this.$moment(date).format("M"),
        year: this.$moment(date).format("YYYY"),
        date: date,
        work_hours: this.selectedHours
      }
      params.project = {
        name: this.$store.getters["basic/projectName"],
        project_id: this.$store.getters["basic/projectId"] ,
        customer_id: this.$store.getters['basic/customerId'],
      }
      this.axios.post('admin/schedule_by_dates', params).then(() => {
        self.$swal("¡Actualizado!", "Cambios guardados con éxito", "success")
        self.closeModal(date)
        self.reloadCustomDates(self, date)
      })
    },
    reloadCustomDates(self, date) {
      self.calendarOptions.events = self.getNewEvents(Array.from(new Set(self.newArrayDates.concat(date))))
    },
    getOptions(hours) {
      if (hours.length === 0) return []
      return hours.map(hour => {
        return {
          text: hour.name,
          value: hour.id
        }
      })
    },
    getHours(date) {
      if (date == '') return []
      let day = this.$store.getters['schedule/week'].find(element => element.day_number === this.$moment(date).day())
      return this.pivotOptions = this.getOptions(day.work_hours)
    },
    async setHours(date) {
      const params = {}
      params.schedule_by_date = {
        date: date,
        customer_id: this.$store.getters['basic/customerId'],
        user_id: this.$store.getters['basic/userId'],
        month_id: this.$moment(date).format("M"),
        year: this.$moment(date).format("YYYY")
      }
      params.project = {
        name: this.$store.getters["basic/projectName"],
        project_id: this.$store.getters["basic/projectId"] ,
        customer_id: this.$store.getters['basic/customerId'],
      }
      let response = await this.axios.get(`admin/schedule_by_dates/dates?${qs.stringify(params)}`)
      if (response.data) {
        let options = this.pivotOptions.map(e =>  e.value)
        let new_work_hours = []
        if (response.data.schedule_type) {
          new_work_hours = response.data.selected_hours
        } else {
          new_work_hours = response.data.work_hours
        }
        this.selectedHours = options.filter(x => (new Set(new_work_hours)).has(x))
      }
    },
    closeModal(date) {
      this.$nextTick(() => {
        this.$bvModal.hide(`detailDay-${date}`)
      })
    },
    openInitialModal(date) {
      this.pivotDate = date
      this.$nextTick(() => {
        this.$bvModal.show(`detailDay-${date}`)
      })
      this.setHours(date)
    },
    handleEventClick(arg) {
      this.selectedHours = []
      let date = arg.event._instance.range.end
      this.openInitialModal(this.$moment(date).format("YYYY-MM-DD"))
    },
    handleDateClick(arg) {
      this.selectedHours = []
      this.openInitialModal(arg.dateStr)
    },
    async setWorkDays(date) {
      let self = this
      let params = {}
      params.schedule = {
        customer_id: this.$store.getters['basic/customerId'],
        user_id: this.$store.getters['basic/userId'],
        month_id: this.$moment(date).format("M"),
        year: this.$moment(date).format("YYYY")
      }
      params.project = {
        name: this.$store.getters["basic/projectName"],
        project_id: this.$store.getters["basic/projectId"] ,
        customer_id: this.$store.getters['basic/customerId'],
      }
      let response = await this.axios.get(`admin/schedules/users?${qs.stringify(params)}`)
      params.week = []
      if (response.data.selected_hours) {
        let customDates = response.data.schedule_by_dates.map(pivotDate => pivotDate.date )
        let end = this.$moment(date).endOf('month').format("YYYY-MM-DD")
        this.$store.getters['schedule/week'].forEach(day => {
          let dayWorkHourIds = day.work_hours.map(option => option.id)
          let intersection = Array.from(new Set(dayWorkHourIds.filter(v=> new Set(response.data.selected_hours).has(v))))
          params.week.push({
            name: day.name,
            dayNumber: day.day_number,
            state: (intersection.length > 0)
          })
        });
        this.getDatesBetween(date, end).forEach(pivotDate => {
          let object = params.week.find(element => element.dayNumber == this.$moment(pivotDate).format('d'))
          if (object.state) { self.newArrayDates.push(pivotDate) }
        })
        this.newArrayDates = Array.from(new Set(self.newArrayDates.concat(customDates)))
        this.calendarOptions.events = this.getNewEvents(this.newArrayDates)
      }
    },
    getNewEvents(dates) {
      return dates.map(date => ({ title: '⏰ Horario de Trabajo', date: date }))
    },
    getDatesBetween(startDate, endDate) {
      let dates = []
      for (let date = this.$moment(startDate); date.isSameOrBefore(endDate); date.add(1, 'days')) {
         dates.push(date.format('YYYY-MM-DD'))
      }
      return dates
    }
  }
}
</script>
