<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab :title="projectName" disabled></b-tab>
        <b-tab no-body title="Calendario" @click="calendario()">
          <b-card-text>
            <div class="content-setting">
              <div>
                <b-alert variant="danger" show v-if="!this.$store.getters['user/existUser']">
                  Actualize sus datos, para crear su calendario.
                </b-alert>
                <b-form-group label="Selecciona Mes" v-if="this.$store.getters['user/existUser']">
                  <month-picker-input ref="monthPicker" :default-month="parseInt(this.$moment().format('M'))" :default-year="parseInt(this.$moment().format('YYYY'))" @change="setDate" lang="es" placeholder="Seleciona Mes" :no-default="true"></month-picker-input>
                </b-form-group>
              </div>
              <div class="content-calendar" v-if="selectedDate">
                <Calendar :date="selectedDate" />
              </div>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab no-body title="Datos">
          <b-card-text>
            <User />
          </b-card-text>
        </b-tab>
        <b-tab no-body title="Estructura base" :disabled="!this.$store.getters['user/existUser']">
          <b-card-text>
            <Setting />
          </b-card-text>
        </b-tab>
        <b-tab no-body title="Herramientas" :disabled="!this.$store.getters['user/existUser']">
          <b-card-text>
            <Tools />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { MonthPickerInput } from 'vue-month-picker'
import Calendar from './Calendar'
import Setting from './Setting'
import Tools from './Tools'
import User from './User'

export default {
  props: {
    token: String,
    customer_id: Number
  },
  name: 'Displayed',
  data(){
    return {
      selectedDate: '',
    }
  },
  computed: {
    projectName() {
        return this.$store.getters['basic/projectName']
    },
    projectId() {
        return this.$store.getters['basic/projectId']
    }
  },
  created() {
  },
  components: {
    Calendar, Setting, User, MonthPickerInput, Tools
  },
  methods: {
    calendario() {
      this.$refs.monthPicker.$data.selectedDate = ''
      this.selectedDate = ''
    },
    setDate(date) {
      this.selectedDate = `${date.year}-${this.$moment(date.monthIndex, 'M').format('MM')}-01`
    }
  }
}
</script>
