import Vue from "vue";
export default {
  namespaced: true,
  state: {
    user: { superUser: false, id: "" },
    customerName: "",
    customerId: "",
    project_id: "",
    project_name: "",
    projects: [],
    version: "v2",
  },
  mutations: {
    setCustomerId: (state, value) => (state.customerId = value),
    setCustomerName: (state, value) => (state.customerName = value),
    setSuperUser: (state, value) => (state.user.superUser = value),
    setUserId: (state, value) => (state.user.id = value),
    setProjectId: (state, value) => (state.project_id = value),
    setVersion(state, value) {
      switch (value) {
        case 1:
          state.version = "v2";
          break;
        case 2:
          state.version = "v3";
          break;
        default:
          state.version = "v2";
      }
    },
    setProjects(state) {
      Vue.axios
        .get(
          `https://cotizacion.mobysuite.com/api/${state.version}/quotes/list-projects`,
          {
            params: {
              real_estate: state.customerName,
            },
          }
        )
        .then((response) => {
          state.projects = response.data.map((project) => ({
            project_id: project.id,
            name: project.nombreProyecto,
          }));
        });
    },
    setProjectName: (state, value) => (state.project_name = value),
  },
  actions: {
    setStateUser: (context, value) => {
      context.commit("setSuperUser", value.superUser);
      context.commit("setUserId", value.user_id);
    },
    setCustomerId: (context, value) => {
      if (value === "") return;
      context.commit("setCustomerId", value);
    },
    setVersion: (context, value) => {
      context.commit("setVersion", value);
    },
    setProjectId: (context, value) => {
      context.commit("setProjectId", value);
    },
    setProjects: (context) => {
      context.commit("setProjects");
    },
    setProjectName: (context, value) => context.commit("setProjectName", value),
    setCustomerName: (context, value) =>
      context.commit("setCustomerName", value),
  },
  getters: {
    customerId: (state) => state.customerId,
    customerName: (state) => state.customerName,
    superUser: (state) => state.user.superUser,
    userId: (state) => state.user.id,
    projectId: (state) => state.project_id,
    projectName: (state) => state.project_name,
    projects: (state) => state.projects,
  },
};
