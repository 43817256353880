import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import store from './store/store'
import VueAxios from 'vue-axios'
import { BootstrapVue } from 'bootstrap-vue'
import VueSwal from 'vue-swal'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import vueCustomElement from 'vue-custom-element'
import Multiselect from 'vue-multiselect'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueSwal)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.component('multiselect', Multiselect)

const instance = axios.create({
	baseURL: process.env.VUE_APP_URL_BACKEND,
})
const moment = require('moment')
Vue.use(require('vue-moment'), {
  moment
})



Vue.use(VueAxios, instance)

Vue.config.productionTip = false
Vue.use(vueCustomElement)

App.store = store

Vue.customElement('admin-meet', App)
